<template>
  <div>
    <!-- LOGO -->
    <img v-if="!lightMode" class="logo relative z-30 mx-auto py-8 cursor-pointer" @click="exitUrl(7)" src="../assets/logos/louisxiii-white.svg" alt="">
    <img v-else class="logo relative z-30 mx-auto py-8 cursor-pointer" @click="exitUrl(7)" src="../assets/logos/louisxiii-black.svg" alt="">
    <!-- TOP RIGHT SHARE -->
    <div class="absolute z-30 share top-10 right-6 md:right-24 cursor-pointer" @click="goToShare()">
      <img class="img-share" src="../assets/icons/share.svg" alt="">
    </div>
    <!-- TOP LEFT RETURN -->
    <div v-if="!hideReturn" class="absolute z-30 return top-8 left-6 md:left-24 cursor-pointer" @click="goToHub()">
      <img src="../assets/icons/left-arrow.svg" alt="">
    </div>
    <!-- LEFT INFORMATION -->
    <div v-if="!hideInfo" class="absolute z-30 -left-8 md:left-10 left-right-overlay transform -rotate-90 -translate-y-1/2 info text-quinary cursor-pointer" @click="goToInformation()">INFORMATION</div>
    <!-- RIGHT CODE -->
    <div v-if="!hideCode" class="absolute z-30 -right-10 md:right-10 left-right-overlay transform rotate-90 -translate-y-1/2 code text-quinary cursor-pointer" :class="{'text-black': lightMode}" @click="goToFind()">I FOUND A CODE</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'OverlayNavigation',
  props: {
    lightMode: {
      type: Boolean,
      required: false,
      default: false
    },
    hideReturn: {
      type: Boolean,
      required: false,
      default: false
    },
    hideInfo: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      lastEnigmaId: "user/lastEnigmaId",
      token: "global/token",
      idu: "user/idu",
      firstname: "user/firstname",
      visitId: "global/visitId",
      appConfig: "config/appConfig"
    })
  },
  methods: {
    exitUrl(linknumber) {
      const iduParam = this.idu != null ? "&iIdU=" + this.idu : "";
      if (linknumber === 7) {
        this.$gtmTracking.logoRedirect()
      }
      window.open(
        `${document.location.protocol}//${document.location.host}/api/v0/${this.appConfig.baseName}/v1/track/page_click?token=${this.token}${iduParam}&iPageId=${this.$route.meta.pageId}&iLinkNumber=${linknumber}&iVisitId=${this.visitId}`,
        "_blank"
      );
    },
    goToHub() {
      this.$router
        .push({ name: 'hub', params: { swiperIndex: this.lastEnigmaId || '1' } })
        .catch(console.log)
    },
    goToShare() {
      this.$gtmTracking.openSharePopin()
      this.$router
        .push({ name: 'share', params: { fromName: this.$route.name } })
        .catch(console.log)
    },
    goToInformation() {
      this.$router
        .push({ name: 'information' })
        .catch(console.log)
    },
    goToFind() {
      this.$router
        .push({ name: 'find', params: { fromName: this.$route.name } })
        .catch(console.log)
    }
  }
}
</script>

<style lang="scss" scoped>
.left-right-overlay {
  top: 40%;
}
@media screen and (min-width: 768px) {
  .img-share {
    width: 28px;
    height: 26px;
  }
  .left-right-overlay {
    top: 50%;
  }
}
</style>